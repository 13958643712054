import style from './carousel.module.scss';

const NavigationButton = ({action, type}) => {
    return (
        <div
            className={style.leftButton}
          >
            {type === "prev" && (
              <button
              style={{
                fill: "var(--white, #FFF)",
                borderRadius: "100%",
                width: "36px",
                height: "36px",
                flexShrink: "0",
                marginRight: "10px",
                border: "none", // Remove border
                backgroundColor: "white",
              }}
              onClick={action}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="6"
                height="12"
                viewBox="0 0 6 12"
                fill="none"
              >
                <path
                  d="M0.75 6L5.25 1.5"
                  stroke="#1F2937"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  opacity="0.4"
                  d="M5.25 10.5L1.45711 6.70711C1.12377 6.37377 0.957107 6.20711 0.957107 6C0.957107 5.79289 1.12377 5.62623 1.45711 5.29289L5.25 1.5"
                  stroke="#1F2937"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
            )}

            {type === "next" && (
              <button
              style={{
                fill: "var(--white, #FFF)",
                borderRadius: "100%",
                width: "36px",
                height: "36px",
                flexShrink: "0",
                border: "none", // Remove border
                backgroundColor: "white",
              }}
              onClick={action}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="6"
                height="12"
                viewBox="0 0 6 12"
                fill="none"
              >
                <path
                  d="M5.25 6L0.75 1.5"
                  stroke="#1F2937"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  opacity="0.4"
                  d="M0.75 10.5L4.54289 6.70711C4.87623 6.37377 5.04289 6.20711 5.04289 6C5.04289 5.79289 4.87623 5.62623 4.54289 5.29289L0.75 1.5"
                  stroke="#1F2937"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
            )}
          </div>
    )
}

export default NavigationButton