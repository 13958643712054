import React from "react";

const Banner = () => {
  return (
    <div className="container mt-md-5 mb-md-5 mt-2 mb-2">
      <div>
        <img src="/images/Banner@3x.png" className="img-fluid" alt="Banner" title="register and Restock Banner"/>
      </div>
    </div>
  );
};

export default Banner;
