import React, { useState, useEffect } from 'react';
import style from './carousel.module.scss';
import NavigationButton from './NavigationButton';

const ItemCarousel = ({ items, title, isLoading }) => {
	const [currentPage, setCurrentPage] = useState(0);
	const [windowWidth, setWindowWidth] = useState(null); // Step 1: Window width state

	useEffect(() => {
		// Update window width state based on the client's window size
		const updateWindowWidth = () => {
			setWindowWidth(window.innerWidth);
		};

		updateWindowWidth(); // Set initial width
		window.addEventListener('resize', updateWindowWidth); // Adjust on resize

		// Cleanup listener on component unmount
		return () => window.removeEventListener('resize', updateWindowWidth);
	}, []);

	const itemsPerPage = getItemsPerPage(windowWidth); // Pass windowWidth as argument

	function getItemsPerPage(width) {
		if (width < 768) {
			return 1;
		} else if (width < 1024) {
			return 2;
		} else {
			return 4;
		}
	}

	const totalPages = Math.ceil(items.length / itemsPerPage);
	const startIndex = currentPage * itemsPerPage;
	const visibleItems = items.slice(startIndex, startIndex + itemsPerPage);

	const handlePrevPage = () => {
		setCurrentPage((prevPage) => Math.max(prevPage - 1, 0));
	};

	const handleNextPage = () => {
		setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages - 1));
	};

	if (isLoading) {
		return <div>Loading...</div>;
	}

	return (
		<div className={`py-3`}>
			<div className='container d-flex flex-column'>
				<p>
					<b>{title !== '' && title}</b>
				</p>
				<div className={`${style.blogCardContainer}`}>
					<ul className='d-flex p-0 w-100 position-relative'>
						{currentPage > 0 && (
							<div className={style.navIconPrev}>
								<NavigationButton type='prev' action={handlePrevPage} />
							</div>
						)}
						{visibleItems.map((item, index) => (
							<li
								key={index}
								style={{ overflow: 'hidden' }}
								className={`${windowWidth >= 991 ? 'me-2' : 'me-0  w-100'}`}>
								<div className=''>{item}</div>
							</li>
						))}
						{currentPage < totalPages - 1 && (
							<div className={style.navIconNext}>
								<NavigationButton type='next' action={handleNextPage} />
							</div>
						)}
					</ul>
				</div>
			</div>
		</div>
	);
};

export default ItemCarousel;
