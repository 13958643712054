import React, { useState, useEffect } from 'react';
import ProductBox from './ProductBox';
const ProductSection = ({
	sectionDetails,
	startIndex,
	endIndex,
	carouselIndices,
}) => {
	const [screenWidth, setScreenWidth] = useState(
		typeof window !== 'undefined' ? window.innerWidth : null
	);

	useEffect(() => {
		const handleResize = () => {
			setScreenWidth(window.innerWidth);
		};

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	return sectionDetails?.items?.length ? (
		<div
			style={
				(screenWidth > 429 && screenWidth < 452) || screenWidth > 767
					? { display: 'flex' }
					: { display: 'flex', justifyContent: 'center' }
			}>
			{sectionDetails.items
				.slice(startIndex, endIndex)
				.map((product, index) => (
					<div key={index} style={{ height: '232px' }}>
						<ProductBox product={product} carouselIndices={carouselIndices} />
					</div>
				))}
		</div>
	) : (
		<></>
	);
};

export default ProductSection;
