import Link from 'next/link';
import Image from 'next/image';
import style from './paginated.module.scss';

const PaginatedCarousal = ({
	label = '',
	section,
	items,
	handleNext,
	handlePrev,
	carouselStartIndex,
}) => {
	return (
		<div className={style.header}>
			<div style={{ display: 'flex', alignItems: 'baseline' }}>
				<div>{label}</div>
				<Link href={`/category/${label.toLowerCase().replace(/\s+/g, '-')}`}>
					See All
				</Link>
			</div>
			<div>
				<button
					onClick={() => handlePrev(section)}
					className={style.productCarousalImages}
					disabled={carouselStartIndex === 0}>
					{carouselStartIndex === 0 && (
						<Image
							src='/images/circle-arrow-left-01-round-invalid.svg'
							width={18}
							height={18}
							alt='prev'
						/>
					)}
					{carouselStartIndex > 0 && (
						<Image
							src='/images/circle-arrow-left-01-round-valid.svg'
							width={18}
							height={18}
							alt='prev'
						/>
					)}
				</button>
				<button
					onClick={() => handleNext(section)}
					className={style.productCarousalImages}
					disabled={carouselStartIndex + 5 >= items?.length}>
					{carouselStartIndex + 5 >= items?.length && (
						<Image
							src='/images/circle-arrow-right-01-sharp-invalid.svg'
							width={18}
							height={18}
							alt='next'
						/>
					)}

					{carouselStartIndex + 5 < items?.length && (
						<Image
							src='/images/circle-arrow-right-01-sharp-valid.svg'
							width={18}
							height={18}
							alt='next'
						/>
					)}
				</button>
			</div>
		</div>
	);
};

export default PaginatedCarousal;
