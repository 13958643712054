import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import ItemCarousel from '../Carousel/ItemCarousel';

const BlogCarousel = () => {
	const [blogData, setBlogData] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	const fetchPharmaPostsItems = async () => {
		const pharma_posts = `${process.env.NEXT_PUBLIC_BACKEND_URL}${process.env.NEXT_PUBLIC_POSTS_API_PATH}?country=${process.env.NEXT_PUBLIC_COUNTRY_CODE}&status=published&search=&blog_type=pharma`;
		try {
			const response = await fetch(pharma_posts, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
				},
			});
			if (!response.ok) {
				throw new Error('Failed to fetch blogs');
			}
			const data = await response.json();
			return data;
		} catch (error) {
			console.error('Error fetching blogs:', error);
			throw error;
		}
	};

	useEffect(() => {
		const fetchPosts = async () => {
			try {
				const postItems = await fetchPharmaPostsItems();
				setBlogData(postItems.data);
				setIsLoading(false);
			} catch (error) {
				console.error('Error fetching data:', error);
			}
		};
		fetchPosts();
	}, []);

	const formatDate = (dateString) => {
		const date = new Date(dateString);
		const options = { month: 'short', day: 'numeric', year: 'numeric' };
		return date.toLocaleDateString('en-US', options);
	};
	return (
		<ItemCarousel
			title={'Blog'}
			isLoading={isLoading}
			items={blogData.map((item, index) => (
				// let wordsArray = item?.title.split(' ')
				// let lastTwoWords = wordsArray.slice(-2).join(' ');
				<div key={index} style={{ display: 'flex' }}>
					<Image
						src={
							item?.featured_image
								? item?.featured_image
								: '/images/no-image.png'
						}
						alt={item?.title.split(' ').slice(0, 3).join(' ')}
						title={item?.title.split(' ').slice(0, 3).join(' ')}
						width={100}
						height={100}
						style={{
							width: 'auto',
							borderRadius: '4px',
							background: `lightgray url(${
								item?.featured_image
									? item?.featured_image
									: './images/no-image.png'
							})`,
							flexShrink: 0,
						}}
					/>
					<div style={{ marginLeft: '10px' }}>
						<span
							style={{
								color: 'var(--gray-800, #1F2937)',
								fontFamily: `'Nunito Sans', sans-serif`,
								fontSize: '14px',
								fontStyle: 'normal',
								fontWeight: 700,
								lineHeight: '20px',
							}}>
							<Link
								className='global-font'
								href={`/articles/${item?.slug}`}
								style={{
									textDecoration: 'none',
									color: 'black',
									// wordBreak: 'break-all',
									maxWidth: '40ch',
								}}>
								{item?.title}
							</Link>
						</span>
						<br />
						<span
							style={{
								color: 'var(--gray-800, #1F2937)',
								fontFamily: `'Nunito Sans', sans-serif`,
								fontSize: '12px',
								fontStyle: 'normal',
								fontWeight: 500,
								lineHeight: '16px',
							}}>
							{item?.author?.fullname}
						</span>

						<span
							style={{
								color: 'var(--gray-500, #6B7280)',
								fontFamily: `'Nunito Sans', sans-serif`,
								fontSize: '12px',
								fontStyle: 'normal',
								fontWeight: '500',
								lineHeight: '16px',
							}}>
							{' '}
							| {formatDate(item?.created_at)}
						</span>
					</div>
				</div>
			))}
		/>
	);
};

export default BlogCarousel;
