import React, { useEffect, useState } from 'react';
import style from './ProductList.module.scss';
import ProductSection from './ProductSection';
import { ClipLoader } from 'react-spinners';
import PaginatedCarousal from '../Carousel/PaginatedCarousel';

const ProductCarousel = ({ loading, section, title, products }) => {
	const [carouselIndices, setCarouselIndices] = useState(0);

	const [windowWidth, setWindowWidth] = useState();

	useEffect(() => setWindowWidth(window.innerWidth), []);

	const calculateCardsQuantity = (width) => {
		// Adjust this logic according to your responsive design requirements
		if (width >= 1200) {
			return 6;
		}
		// else if (width >= 1200) {
		// 	return 5;
		// }
		else if (width >= 992) {
			return 4;
		}
		// else if (width >= 660) {
		else if (width >= 768) {
			return 3;
		} else if (width >= 430) {
			return 2;
		} else {
			return 1;
		}
	};

	const [CARDS_QUANTITY_EACH_SECTION, setCardsQuantity] = useState(
		calculateCardsQuantity(windowWidth)
	);

	useEffect(() => {
		const handleResize = () => {
			setWindowWidth(window.innerWidth);
		};

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	useEffect(() => {
		setCardsQuantity(calculateCardsQuantity(windowWidth));
	}, [windowWidth]);

	const handleNext = () => {
		console.log(`${section}StartIndex` + CARDS_QUANTITY_EACH_SECTION);
		setCarouselIndices(Math.max(0, carouselIndices + 5));
	};

	const handlePrev = () => {
		console.log(Math.max(0, `${section}StartIndex` - 5));
		setCarouselIndices(Math.max(0, carouselIndices - 5));
	};

	return (
		<>
			<div className={style.productList}>
				<PaginatedCarousal
					section={`${section}StartIndex`}
					items={products}
					label={title}
					handleNext={handleNext}
					handlePrev={handlePrev}
					carouselStartIndex={carouselIndices}
				/>
				{!loading ? (
					<ProductSection
						sectionDetails={{ label: section, items: products }}
						startIndex={carouselIndices}
						endIndex={carouselIndices + CARDS_QUANTITY_EACH_SECTION}
						carouselIndices={carouselIndices}
					/>
				) : (
					<div style={{ textAlign: 'center' }}>
						<ClipLoader color='rgb(226, 21, 106)' loading={true} />
					</div>
				)}
			</div>
		</>
	);
};

export default ProductCarousel;
