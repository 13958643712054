'use client';

import CategoriesMenuLoader from '@/components/CategoryMenus/CategoryMenuWrapper';
import ProductCarousel from '@/components/Products/ProductCarousel';
import BlogCarousel from '@/components/BlogSection/BlogCarousel';
import { useState, useEffect } from 'react';
import Layout from '@/components/Layout';
import Banner from '@/components/DiscountsPromotion/Banner';
import style from '../components/Carousel/carousel.module.scss';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const Home = () => {
	const [isFeaturedProductsLoading, setIsFeaturedProductsLoading] =
		useState(false);
	const [categoryWiseProducts, setCategoryWiseProducts] = useState([]);

	const [isClient, setIsClient] = useState(false);

	useEffect(() => {
		setIsClient(true);
	}, []);

	useEffect(() => {
		getAllFeaturedProducts();
	}, []);

	const getAllFeaturedProducts = async () => {
		const userData = JSON.parse(localStorage.getItem('pharma_customer'));

		try {
			setIsFeaturedProductsLoading(true);
			const apiUrl = `${process.env.NEXT_PUBLIC_BACKEND_URL}${
				process.env.NEXT_PUBLIC_PHARMA_FEATURED_PRODUCTS
			}?country=${process.env.NEXT_PUBLIC_COUNTRY_CODE}&location=${
				userData ? userData?.location : ''
			}`;

			const response = await fetch(apiUrl, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
				},
			});

			const result = await response.json();

			if (result && result.data) {
				const allProductsArray = result.data;

				// Function to filter products by inventory
				const filterProductsByInventory = (categorywiseProducts) => {
					const filteredCategories = {};
					for (const [category, products] of Object.entries(
						categorywiseProducts
					)) {
						filteredCategories[category] = products.filter(
							(product) => product.total_inventory > 0
						);
					}
					return filteredCategories;
				};

				const filteredProducts = filterProductsByInventory(allProductsArray);
				setCategoryWiseProducts(filteredProducts);
			} else {
				console.error('Invalid featured products data:', result);
			}
			setIsFeaturedProductsLoading(false);
		} catch (error) {
			setIsFeaturedProductsLoading(false);
			console.error('Fetch error:', error);
		}
	};

	return (
		<>
			{isClient ? (
				<Layout>
					<HelmetProvider>
						<Helmet>
							<title>Kasha Home</title>
							<meta name='description' content='Kasha Home' />
							<link
								rel='canonical'
								href={
									process.env.NEXT_PUBLIC_COUNTRY_CODE === 'KE'
										? 'https://pharma.kasha.co.ke/'
										: 'https://pharma.kasha.rw/'
								}
							/>
						</Helmet>
					</HelmetProvider>
					<div className='container-fluid px-0'>
						<CategoriesMenuLoader />
						<Banner />
					</div>

					<div className='container'>
						<ProductCarousel
							title={'Best Selling Items'}
							loading={isFeaturedProductsLoading}
							section={'bestSellerItems'}
							products={
								categoryWiseProducts
									? categoryWiseProducts?.bestSellingItems
									: []
							}
						/>
						<ProductCarousel
							title={'Reproductive Health'}
							loading={isFeaturedProductsLoading}
							section={'reproductiveHealth'}
							products={
								categoryWiseProducts
									? categoryWiseProducts?.reproductiveHealth
									: []
							}
						/>
					</div>

					<div className={`${style.blogContainer} container-fluid p-0`}>
						<BlogCarousel></BlogCarousel>
					</div>

					<div className='container'>
						<ProductCarousel
							title={'Chronic Diseases'}
							loading={isFeaturedProductsLoading}
							section={'chronicDiseases'}
							products={
								categoryWiseProducts
									? categoryWiseProducts?.chronicDiseases
									: []
							}
						/>
						<ProductCarousel
							title={'Pain Management'}
							loading={isFeaturedProductsLoading}
							section={'painManagement'}
							products={
								categoryWiseProducts ? categoryWiseProducts?.painManagement : []
							}
						/>
						<ProductCarousel
							title={'Infectious Diseases'}
							loading={isFeaturedProductsLoading}
							section={'infectiousHealth'}
							products={
								categoryWiseProducts
									? categoryWiseProducts?.infectiousDiseases
									: []
							}
						/>
						<ProductCarousel
							title={'Respiratory Health'}
							loading={isFeaturedProductsLoading}
							section={'respiratoryHealth'}
							products={
								categoryWiseProducts
									? categoryWiseProducts?.respiratoryHealth
									: []
							}
						/>
					</div>
				</Layout>
			) : null}
		</>
	);
};

export default Home;
